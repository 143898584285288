import { Col, Row } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import {
  Chat, Empty, Extra, FooterContainer, FooterSection, Language, Large, LogoContainer, NavLink, Para
} from "./styles";


interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  // const handleChange = (language: string) => {
  //   i18n.changeLanguage(language);
  // };

  // const SocialLink = ({ href, src }: SocialLinkProps) => {
  //   return (
  //     <a
  //       href={href}
  //       target="_blank"
  //       rel="noopener noreferrer"
  //       key={src}
  //       aria-label={src}
  //     >
  //       <SvgIcon src={src} width="25px" height="25px" />
  //     </a>
  //   );
  // };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <Large to="/">{t("Tell us everything")}</Large>

              <a href="tel:+916361302343">
                <Chat>{t(`+91 63613 02343`)}</Chat>
              </a>
              <a href="tel:+919908898589">
                <Chat>{t(`+91 99088 98589`)}</Chat>
              </a>
              <a href="tel:+919686425052">
                <Chat>{t(`+91 96864 25052`)}</Chat>
              </a>
              <a href="mailto:naresh@nestedhomes.in">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Address")}</Language>
              <Large to="/">{t("Nested Homes")}</Large>
              <Para>
                {t(`Srinidhi Building, E-Block`)}
              </Para>
              <Para>
                {t(`Landmark: Behind Swathi Gardenia`)}
              </Para>
              <Para>
                {t(`Sahakarnagar, Bengaluru`)}
              </Para>
            </Col>
          </Row>
          
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              Nested Homes | Copyright © {new Date().getFullYear()} | Developed and Maintained by &nbsp;<a href="https://nullcrew.tech">NullCrew Technologies</a>
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
